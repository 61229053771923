* {
  box-sizing: border-box;
  margin: 0;
}

.link {
  display: block;
  text-align: center;
  color: #777;
  text-decoration: none;
  padding: 10px;
}

.movie_card {
  position: relative;
  display: block;
  /* width: 800px; */
  height: 350px;
  margin: 80px auto;
  overflow: hidden;
  border-radius: 10px;
  transition: all 0.4s;
  box-shadow: 0px 0px 120px -25px rgba(0, 0, 0, 0.5);
}

.movie_card:hover {
  transform: scale(1.02);
  transition: all 0.4s;
}

.movie_card .info_section {
  position: relative;
  width: 100%;
  height: 100%;
  background-blend-mode: multiply;
  z-index: 2;
  border-radius: 10px;
}

.movie_card button {
  position: absolute;
  bottom: 30px;
  right: 30px;
  z-index: 9;
}

.movie_card button {
  border-radius: 50px;
}

.movie_card .info_section .movie_header {
  position: relative;
  padding: 25px;
  height: 40%;
}

.movie_card .info_section .movie_header h1 {
  color: black;
  font-weight: 400;
}

.movie_card .info_section .movie_header h1 span {
  color: #808b96 !important;
}

.movie_card .info_section .movie_header h4 {
  color: #555;
  font-weight: 400;
}

.movie_card .info_section .movie_header .minutes {
  display: inline-block;
  margin-top: 15px;
  color: #555;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.movie_card .info_section .movie_header .type {
  display: inline-block;
  color: #959595;
  margin-left: 10px;
}

.movie_card .info_section .movie_header .locandina {
  position: relative;
  float: left;
  margin-right: 20px;
  height: 120px;
  box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.5);
}

.movie_card .info_section .movie_desc {
  padding: 25px;
  height: 50%;
}

.movie_card .info_section .movie_desc .text {
  color: #545454;
}

.movie_card .info_section .movie_social {
  height: 10%;
  padding-left: 15px;
  padding-bottom: 20px;
}

.movie_card .info_section .movie_social ul {
  list-style: none;
  padding: 0;
}

.movie_card .info_section .movie_social ul li {
  display: inline-block;
  color: rgba(0, 0, 0, 0.3);
  transition: color 0.3s;
  transition-delay: 0.15s;
  margin: 0 10px;
}

.movie_card .info_section .movie_social ul li:hover {
  transition: color 0.3s;
  color: rgba(0, 0, 0, 0.7);
}

.movie_card .info_section .movie_social ul li i {
  font-size: 19px;
  cursor: pointer;
}

.movie_card .blur_back {
  position: absolute;
  top: 0;
  z-index: 1;
  height: 100%;
  right: 0;
  background-size: cover !important;
  border-radius: 11px;
}

@media screen and (min-width: 768px) {
  .movie_header {
    width: 65%;
  }

  .movie_desc {
    width: 50%;
  }

  .info_section {
    background: linear-gradient(to right, #e5e6e6 50%, transparent 100%);
  }

  .blur_back {
    width: 80%;
    background-position: -100% 10% !important;
  }
}

@media screen and (max-width: 768px) {
  .movie_card {
    width: 95%;
    margin: 70px auto;
    min-height: 350px;
    height: auto;
  }

  .blur_back {
    width: 100%;
    background-position: 50% 50% !important;
  }

  .movie_header {
    width: 100%;
    margin-top: 85px;
  }

  .movie_desc {
    width: 100%;
  }

  .info_section {
    background: linear-gradient(to top, #e5e6e6 50%, transparent 100%);
    display: inline-grid;
  }
}

@media screen and (min-width: 768px) {
  .blur_back {
    left: 40%;
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .movie_card {
    width: 100%;
  }
}
