div[id='root'] {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  display: none;
}

/* For table hover color */
.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
  background-color: rgba(0, 0, 0, 0.04);
}

/* First and last element of a table row padding */
.card tr td:first-child,
.card tr th:first-child {
  padding-left: 1.5rem;
}

.card tr td:last-child,
.card tr th:last-child {
  padding-right: 1.5rem;
}

.borderless td,
.borderless th {
  border: none;
}

/* Overwrite Tabler-react css, this causes duplicate of modal close button */
.close:before {
  content: '' !important;
  font-family: '' !important;
}
.close {
  font-size: 1.5rem !important;
  line-height: 1 !important;
}

.loader {
  position: inherit;
}

.card {
  border-radius: 5px;
}

.logo-container {
  position: relative;
  height: 30px;
}

.logo {
  position: absolute;
  top: 10px;
  left: 10px;
}

@media only screen and (max-width: 1280px) {
  .card-aside {
    min-height: 200px;
  }
}

@media only screen and (min-width: 1280px) {
  .card-aside {
    min-height: 250px;
  }
}

.price {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.public_list {
  margin: 0;
  background: white;
  font-family: 'Montserrat', helvetica, arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  height: auto;
  min-height: 100%;
  padding-bottom: 20px;
}

.public_list .page-header {
  margin-bottom: -40px;
}

/* html,
body {
  height: 100%;
}

body {
  background-color: #f5f5f5;
  padding-top: 48px;
}

.alert-div {
  height: auto;
  position: absolute;
  display: block;
  width: 100%;
  margin-left: -15px;
}

.pos-abs {
  position: absolute;
}

.row-full {
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.modal.show .modal-dialog {
  height: auto;
}

.modal.fade {
  height: auto;
}

.modal-backdrop {
  height: auto;
}
 */
/* .modal-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
} */

/* .dialog {
  display: flex !important;
} */

/* .alert {
  height: 50px;
} */

/* .App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.box {
  background-color: grey;
  flex-direction: column;
  justify-content: center;
  height: 100%;
} */

/* body {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
} */

/*
.navbar .form-control {
  padding: 0.75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}

.col-md-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-sm-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
 */
